import React from 'react';
import Text from '../../Typography';
import Button from '../../Button';
import Icon from '../../Icon';
import Form from '../../Form';
import { Section, Wrapper, Container, Row } from '../styles/Contact.styled';

const Contact = ({ data }) => (
	<Section id={data.id}>
		<Wrapper>
			<Container>
				<Text Element="h5" text={data?.headingKicker} />
				<Text
					Element="h2"
					text={data?.heading}
					fontWieght="800"
					classes="mt-2 mb-4"
				/>
				<Text Element="div" html={data?.subhead} fontSize="1.125rem" />
				<Row>
					<Button
						Element="a"
						to="tel:8582327090"
						classes="flex items-center  my-5 hover:opacity-50"
					>
						<Icon
							type="fas"
							icon="phone"
							color="white"
							classes="grid mr-3 text-lg text-white rounded-full w-9 h-9 place-items-center"
							styles="background: #d97706"
						/>
						<Text Element="span" text="(858) 232-7090" />
					</Button>
					<Button
						Element="a"
						to="mailto:jordanesguerra93@gmail.com"
						classes="flex items-center  my-5 hover:opacity-50"
					>
						<Icon
							type="fas"
							icon="envelope"
							color="white"
							classes="grid mr-3 text-lg text-white rounded-full w-9 h-9 place-items-center"
							styles="background: #d97706"
						/>
						<Text Element="span" text="jordanesguerra93@gmail.com" />
					</Button>
				</Row>
			</Container>
			<Container>
				<Form data={data?.featuredContent} />
			</Container>
		</Wrapper>
	</Section>
);

export default Contact;
