import React from 'react';
import loadable from '@loadable/component';

const Form = ({ data }) => {
	switch (data.id) {
		case 'DatoCmsEntityForm-10301065-en':
			const ContactForm = loadable(() => import('./ContactForm'));
			return <ContactForm />;
		default:
			return null;
	}
};

export default Form;
