import styled from 'styled-components';

export const Section = styled.section`
	width: 100%;
	height: auto;
	padding: 0px;
	background: #f9fafb;
`;
export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;
export const Container = styled.div`
	flex-basis: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 64px;
	&:last-child {
		background: white;
	}
	@media (max-width: 768px) {
		width: 100%;
		flex-basis: 100%;
		padding: 32px;
	}
`;
export const Row = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 24px;
	a {
		padding: 0px;
		margin-bottom: 12px;
		&:hover {
			opacity: 0.7;
		}
	}
`;
